import React from 'react';
import '../ScreenShotScreen/ScreenShotLoader.css';
import ImageRenderer from '../Common/ImageRenderer';

export default function ScreenShotLoader({ title, imgSrc }) {
  return (
    <div className="screen-shot-loader card">
      <p className="screen-shot-title header-4">{title}</p>
      <ImageRenderer className="screen-shot" src={imgSrc} alt="" />
    </div>
  );
}
