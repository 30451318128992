import React from 'react';
import ColorScreens from './ColorScreens';
import { PricingMobile, PricingNew } from '../../assets/index';
import useIsMobile from '../../hooks/useIsMobile';

const PricingModelAd = () => {
  const isMobile = useIsMobile();

  const content = {
    title: 'Cutting-Edge Software at Forwarder-Friendly Prices',
    imageSrc: isMobile ? PricingMobile : PricingNew,
    backgroundColor: '#FFF2E2',
    titleColor: '#7A1600',
  };

  return (
    <div id={'pricing'}>
      <ColorScreens {...content}>
        <p className="colorpage-text body-text-1">
          Whether you're a growing freight forwarder or a large logistics powerhouse,{' '}
          <strong>Shipmnts has a plan that fits your needs</strong>. Get powerful tools to
          streamline operations, automate workflows, and scale your business—without the complexity.
        </p>
      </ColorScreens>
    </div>
  );
};

export default PricingModelAd;
