import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Redirector = ({ pattern, target }) => {
  const location = useLocation();

  useEffect(() => {
    const regex = new RegExp(pattern);
    const match = location.pathname.match(regex);
    if (match) {
      const newUrl = target.replace(/\$(\d+)/g, (_, index) => match[index] || '');
      window.location.href = newUrl;
    }
  }, [location, pattern, target]);

  return <Skeleton active />;
};

export default Redirector;
