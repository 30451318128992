import React from 'react';
import '../Arcade/Arcade.css';
import useIsMobile from '../../hooks/useIsMobile';

export default function Arcade() {
  const isMobile = useIsMobile();

  return (
    <section className="arcade">
      <div className="arcade-section-title-container">
        <p className="section-title header-2">Take a Closer Look</p>
      </div>
      <div
        style={{
          position: 'relative',
          display: 'block',
          height: '100%',
          minHeight: isMobile ? '30vh' : '70vh',
          width: isMobile ? '80vw' : '60vw',
        }}
      >
        <iframe
          class="arcade-collection"
          src="https://demo.arcade.software/bVLGPxM00ziweXZbgJsB?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
          title="Experience Shipmnts"
          frameborder="0"
          loading="lazy"
          allowFullScreen
          allow="fullscreen"
          sandbox="allow-scripts allow-same-origin allow-top-navigation-by-user-activation allow-popups"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            'color-scheme': 'light',
          }}
        ></iframe>
      </div>
    </section>
  );
}
