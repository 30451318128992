import React from 'react';
import { HeaderLogo, LinkedIn } from '../../assets';
import '../AboutSection/AboutSection.css';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';

export default function AboutSection() {
  const socials = [
    {
      label: 'LinkedIn',
      icon: LinkedIn,
      link: 'https://in.linkedin.com/company/shipmnts',
    },
  ];

  const OtherLinks = [
    {
      label: 'Blog',
      link: 'https://blog.shipmnts.com/',
    },
    {
      label: 'Privacy Policy',
      link: '/privacy-policy',
      type: 'internal',
    },
    {
      label: 'Help',
      link: 'https://help.shipmnts.com/portal',
    },
  ];

  return (
    <div className="about-container">
      <div id="about" className="about-section">
        <div className="about-content">
          <img src={HeaderLogo} alt="SHIPMNTS" className="footer-logo" />
          <div className="body-text-3">
            We are a tech platform for Freight Forwarders, CHAs,Transporters, 4PLs, Importers and
            Exporters. Shipmnts streamlines Sales, Pricing, Documentation, Cargo Operations,
            Billing, and Compliance - all in one place.
          </div>
          <div className="body-text-3">
            Each tool is powerful alone, but together, they transform how logistics businesses
            operate.
          </div>
          <div className="orange-text body-text-3">
            The only app you'll ever need to run your business.
          </div>
        </div>
        <div className="links-section">
          <div className="link-section-child">
            <div className="about-heading body-text-3">Contact us</div>
            <a
              href="mailto:sales@shipmnts.com"
              className="body-text-3"
              style={{
                textDecoration: 'underline',
              }}
            >
              sales@shipmnts.com
            </a>
          </div>
          <div className="other-links link-section-child">
            <div className="about-heading body-text-3">Other Links</div>
            <div className="link-container">
              {OtherLinks.map((link, index) => (
                <div>
                  {link?.type !== 'internal' ? (
                    <a
                      key={index}
                      href={link.link}
                      target="_blank"
                      rel="noreferrer"
                      className="body-text-3"
                    >
                      {link.label}
                    </a>
                  ) : (
                    <Link to={link.link} className="body-text-3">
                      {link.label}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="socials-section">
          <div>
            <div className="about-heading body-text-3">Follow us on</div>
            <div className="socials">
              {socials.map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <img src={social.icon} alt={social.label} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Divider className="about-divider" />
      <div className="footer-text-container">
        <span className="crafted-text body-text-4">Crafted with 💜 in India</span>
        <span className="copyright-text body-text-4">
          ©2025 Logistixian Technologies Private Limited.
        </span>
      </div>
    </div>
  );
}
