import React from 'react';
import { Helmet } from 'react-helmet';

const TermsOfServices = () => {
  return (
    <section className="first-section">
      <Helmet>
        <title>Terms Of Services | Shipmnts</title>
        <meta name="description" content="Terms Of Services" />
      </Helmet>
      <div>
        <h2 className="header-2">Terms of Service</h2>
        <h1 className="header-2">Website Terms and Conditions of Use</h1>
        <h2 className="header-3">1. Terms</h2>
        <p className="body-text-2">
          By accessing this Website, accessible from http://shipmnts.com, you are agreeing to be
          bound by these Website Terms and Conditions of Use and agree that you are responsible for
          the agreement with any applicable local laws. If you disagree with any of these terms, you
          are prohibited from accessing this site. The materials contained in this Website are
          protected by copyright and trade mark law.
        </p>
        <h2 className="header-3">2. Use License</h2>
        <p className="body-text-2">
          Permission is granted to temporarily download one copy of the materials on Logistixian
          Technologies Pvt Ltd's Website for personal, non-commercial transitory viewing only. This
          is the grant of a license, not a transfer of title, and under this license you may not:
        </p>
        <ul>
          <li className="body-text-2">modify or copy the materials;</li>
          <li className="body-text-2">
            use the materials for any commercial purpose or for any public display;
          </li>
          <li className="body-text-2">
            attempt to reverse engineer any software contained on Logistixian Technologies Pvt Ltd's
            Website;
          </li>
          <li className="body-text-2">
            remove any copyright or other proprietary notations from the materials; or
          </li>
          <li className="body-text-2">
            transferring the materials to another person or "mirror" the materials on any other
            server.
          </li>
        </ul>
        <p className="body-text-2">
          This will let Logistixian Technologies Pvt Ltd to terminate upon violations of any of
          these restrictions. Upon termination, your viewing right will also be terminated and you
          should destroy any downloaded materials in your possession whether it is printed or
          electronic format.
        </p>
        <h2 className="header-3">3. Disclaimer</h2>
        <p className="body-text-2">
          All the materials on Logistixian Technologies Pvt Ltd's Website are provided "as is".
          Logistixian Technologies Pvt Ltd makes no warranties, may it be expressed or implied,
          therefore negates all other warranties. Furthermore, Logistixian Technologies Pvt Ltd does
          not make any representations concerning the accuracy or reliability of the use of the
          materials on its Website or otherwise relating to such materials or any sites linked to
          this Website.
        </p>
        <h2 className="header-3">4. Limitations</h2>
        <p className="body-text-2">
          Logistixian Technologies Pvt Ltd or its suppliers will not be hold accountable for any
          damages that will arise with the use or inability to use the materials on Logistixian
          Technologies Pvt Ltd’s Website, even if Logistixian Technologies Pvt Ltd or an authorize
          representative of this Website has been notified, orally or written, of the possibility of
          such damage. Some jurisdiction does not allow limitations on implied warranties or
          limitations of liability for incidental damages, these limitations may not apply to you.
        </p>
        <h2 className="header-3">5. Revisions and Errata</h2>
        <p className="body-text-2">
          The materials appearing on Logistixian Technologies Pvt Ltd’s Website may include
          technical, typographical, or photographic errors. Logistixian Technologies Pvt Ltd will
          not promise that any of the materials in this Website are accurate, complete, or current.
          Logistixian Technologies Pvt Ltd may change the materials contained on its Website at any
          time without notice. Logistixian Technologies Pvt Ltd does not make any commitment to
          update the materials.
        </p>
        <h2 className="header-3">6. Links</h2>
        <p className="body-text-2">
          Logistixian Technologies Pvt Ltd has not reviewed all of the sites linked to its Website
          and is not responsible for the contents of any such linked site. The presence of any link
          does not imply endorsement by Logistixian Technologies Pvt Ltd of the site. The use of any
          linked website is at the user’s own risk.
        </p>
        <h2 className="header-3">7. Site Terms of Use Modifications</h2>
        <p className="body-text-2">
          Logistixian Technologies Pvt Ltd may revise these Terms of Use for its Website at any time
          without prior notice. By using this Website, you are agreeing to be bound by the current
          version of these Terms and Conditions of Use.
        </p>
        <h2 className="header-3">8. Governing Law</h2>
        <p className="body-text-2">
          Any claim related to Logistixian Technologies Pvt Ltd's Website shall be governed by the
          laws of in without regards to its conflict of law provisions.
        </p>
      </div>
    </section>
  );
};

export default TermsOfServices;
