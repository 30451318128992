import React from 'react';
import ColorScreens from './ColorScreens';
import { SecurityMobile, SecurityNew } from '../../assets/index';
import useIsMobile from '../../hooks/useIsMobile';

const SecuritySection = () => {
  const isMobile = useIsMobile();

  const content = {
    title: 'With Great Power Comes Great Security',
    imageSrc: isMobile ? SecurityMobile : SecurityNew,
    backgroundColor: '#FAE8FF',
    titleColor: '#60007B',
  };

  return (
    <div id={'security'}>
      <ColorScreens {...content}>
        <p className="colorpage-text body-text-1">
          A highly compatible system with <strong>24/7 availability</strong>, global disaster
          recovery, <strong>automated hourly backups</strong>, data maintenance, MFA, and 256-bit
          encryption for both transit and <strong>storage</strong>, ensuring seamless business
          continuity.
        </p>
      </ColorScreens>
    </div>
  );
};

export default SecuritySection;
