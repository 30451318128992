import './App.css';
import React from 'react';
import Header from './components/Header/Header';
import { ConfigProvider } from 'antd';
import ImageViewerModal from './components/Common/ImageViewer';
import FloatingButton from './components/Common/FloatButton';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Home from './components/Home';
import TermsOfServices from './components/TermsOfServices/TermsOfServices';
import Redirector from './components/Common/Redirector';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#fe6533',
          controlHeightLG: '60px',
        },
      }}
    >
      <>
        <ImageViewerModal />
        <FloatingButton shape="square" />

        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-services" element={<TermsOfServices />} />
          <Route
            path="blog/*"
            element={<Redirector pattern="^/blog(.*)$" target="https://blog.shipmnts.com$1" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    </ConfigProvider>
  );
}

export default App;
